var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":""},on:{"click:outside":_vm.close},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"headline feedback-title",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('layout.feedback-title'))+" "),_c('v-spacer'),_c('v-icon',{staticClass:"close-btn",attrs:{"color":"white"},on:{"click":_vm.close}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('br'),_c('div',{staticClass:"feedback-type-title"},[_vm._v("Feedback type")]),_c('v-item-group',[_c('v-container',{staticClass:"container-dense"},[_c('v-row',{staticClass:"options"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex align-center type-card",attrs:{"height":"100","hover":"","tile":"","color":_vm.payload.Type === _vm.FEEDBACKTYPE_GENERAL ? 'secondary' : ''},on:{"click":function($event){_vm.payload.Type =
                    _vm.payload.Type === _vm.FEEDBACKTYPE_GENERAL
                      ? ''
                      : _vm.FEEDBACKTYPE_GENERAL}}},[_c('v-icon',{class:_vm.payload.Type === _vm.FEEDBACKTYPE_GENERAL
                      ? 'type-icon-active'
                      : 'type-icon'},[_vm._v("mdi-message-text-outline")]),_c('span',{class:_vm.payload.Type === _vm.FEEDBACKTYPE_GENERAL
                      ? 'type-name-active'
                      : 'type-name'},[_vm._v(" "+_vm._s(_vm.$t('layout.feedback-general')))]),_c('v-scroll-y-transition',[(_vm.payload.Type === _vm.FEEDBACKTYPE_GENERAL)?_c('div',{staticClass:"display-3 flex-grow-1"}):_vm._e()])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex align-center type-card",attrs:{"height":"100","hover":"","tile":"","color":_vm.payload.Type === _vm.FEEDBACKTYPE_IMPROVEMENT ? 'secondary' : ''},on:{"click":function($event){_vm.payload.Type =
                    _vm.payload.Type === _vm.FEEDBACKTYPE_IMPROVEMENT
                      ? ''
                      : _vm.FEEDBACKTYPE_IMPROVEMENT}}},[_c('v-icon',{class:_vm.payload.Type === _vm.FEEDBACKTYPE_IMPROVEMENT
                      ? 'type-icon-active'
                      : 'type-icon'},[_vm._v("mdi-lightbulb-on-outline")]),_c('span',{class:_vm.payload.Type === _vm.FEEDBACKTYPE_IMPROVEMENT
                      ? 'type-name-active'
                      : 'type-name'},[_vm._v(_vm._s(_vm.$t('layout.feedback-suggestion')))]),_c('v-scroll-y-transition',[(_vm.payload.Type === _vm.FEEDBACKTYPE_IMPROVEMENT)?_c('div',{staticClass:"display-3 flex-grow-1"}):_vm._e()])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex align-center type-card",attrs:{"height":"100","hover":"","tile":"","color":_vm.payload.Type === _vm.FEEDBACKTYPE_BUG ? 'error' : ''},on:{"click":function($event){_vm.payload.Type =
                    _vm.payload.Type === _vm.FEEDBACKTYPE_BUG ? '' : _vm.FEEDBACKTYPE_BUG}}},[_c('v-icon',{class:_vm.payload.Type === _vm.FEEDBACKTYPE_BUG
                      ? 'type-icon-active'
                      : 'type-icon'},[_vm._v("mdi-bug-outline")]),_c('span',{class:_vm.payload.Type === _vm.FEEDBACKTYPE_BUG
                      ? 'type-name-active'
                      : 'type-name'},[_vm._v(_vm._s(_vm.$t('layout.feedback-report-bug')))]),_c('v-scroll-y-transition',[(_vm.payload.Type === _vm.FEEDBACKTYPE_BUG)?_c('div',{staticClass:"display-3 flex-grow-1"}):_vm._e()])],1)],1)],1)],1)],1),_c('br'),_c('div',{staticClass:"feedback-type-title"},[_vm._v(_vm._s(_vm.$t('common.message')))]),_c('v-textarea',{attrs:{"solo":"","name":"feedback-text","no-resize":"","auto-grow":"","autofocus":""},model:{value:(_vm.payload.Text),callback:function ($$v) {_vm.$set(_vm.payload, "Text", $$v)},expression:"payload.Text"}}),_c('div',{staticClass:"feedback-type-title"},[_vm._v(" "+_vm._s(_vm.$t('layout.feedback-feelings'))+" ")]),_c('v-item-group',[_c('v-container',{staticClass:"container-dense"},[_c('v-row',{staticClass:"options align-center"},[_c('v-icon',{class:_vm.payload.Emotion === _vm.EMOTION_HAPPY
                  ? 'emotion-icon-active-happy'
                  : 'emotion-icon',on:{"click":function($event){_vm.payload.Emotion =
                  _vm.payload.Emotion === _vm.EMOTION_HAPPY ? '' : _vm.EMOTION_HAPPY}}},[_vm._v("mdi-emoticon-excited-outline")]),_c('v-icon',{class:_vm.payload.Emotion === _vm.EMOTION_DONT_KNOW
                  ? 'emotion-icon-active-neutral'
                  : 'emotion-icon',on:{"click":function($event){_vm.payload.Emotion =
                  _vm.payload.Emotion === _vm.EMOTION_DONT_KNOW
                    ? ''
                    : _vm.EMOTION_DONT_KNOW}}},[_vm._v("mdi-emoticon-neutral-outline")]),_c('v-icon',{class:_vm.payload.Emotion === _vm.EMOTION_SAD
                  ? 'emotion-icon-active-sad'
                  : 'emotion-icon',on:{"click":function($event){_vm.payload.Emotion =
                  _vm.payload.Emotion === _vm.EMOTION_SAD ? '' : _vm.EMOTION_SAD}}},[_vm._v("mdi-emoticon-confused-outline")])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"feedback-actions"},[(!_vm.feedback)?_c('v-btn',{attrs:{"text":"","loading":_vm.stopping},on:{"click":_vm.stop}},[_vm._v(_vm._s(_vm.$t('buttons.stop-reminder')))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.cancelling},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('buttons.cancel'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"color":"secondary","disabled":!_vm.valid,"loading":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.okey'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }