






























































































































































































































































































































import {
  defineComponent,
  onMounted,
  computed,
  ref,
} from '@vue/composition-api';
import { useGlobal } from '@/modules/global';
import { Feedback } from '@/models/common/interfaces';
import {
  EMOTION_HAPPY,
  EMOTION_DONT_KNOW,
  EMOTION_SAD,
  FEEDBACKTYPE_GENERAL,
  FEEDBACKTYPE_IMPROVEMENT,
  FEEDBACKTYPE_BUG,
} from '@/constants/common';

export default defineComponent({
  name: 'KOFeedbackModal',
  setup() {
    const {
      getSettings,
      addFeedback,
      postponeFeedback,
      stopFeedbackReminders,
      feedbackTime,
      feedback,
    } = useGlobal();

    const payload = ref<Feedback>({
      Type: FEEDBACKTYPE_GENERAL,
      Text: '',
      Emotion: '',
    });
    const submitting = ref<boolean>(false);
    const cancelling = ref<boolean>(false);
    const stopping = ref<boolean>(false);

    onMounted(async () => {
      await getSettings();
    });

    const clean = () => {
      payload.value = {
        Type: FEEDBACKTYPE_GENERAL,
        Text: '',
        Emotion: '',
      };
    };

    const active = computed(() => {
      return feedbackTime() || feedback.value;
    });

    const close = async () => {
      cancelling.value = true;
      await postponeFeedback();
      clean();
      cancelling.value = false;
    };

    const stop = async () => {
      stopping.value = true;
      await stopFeedbackReminders();
      clean();
      stopping.value = false;
    };

    const valid = computed(() => {
      return payload.value.Type !== '' && payload.value.Text !== '';
    });

    const submit = async () => {
      if (valid) {
        submitting.value = true;
        await addFeedback(payload.value);
        clean();
        submitting.value = false;
      }
    };

    return {
      EMOTION_HAPPY,
      EMOTION_DONT_KNOW,
      EMOTION_SAD,
      FEEDBACKTYPE_GENERAL,
      FEEDBACKTYPE_IMPROVEMENT,
      FEEDBACKTYPE_BUG,
      active,
      payload,
      valid,
      submit,
      close,
      stop,
      feedback,
      submitting,
      stopping,
      cancelling,
    };
  },
});
